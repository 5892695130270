import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "react-modal"
import { VscChromeClose, VscArrowDown } from "react-icons/vsc"
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"

// Component
import SEO from "./seo"
import AudioPlayer from "./audioPlayer"
import VideoPlayer from "./videoPlayer"
import styles from "./app.module.css"

// Assets
import MainSVG from "../assets/svg/bg-optimized.svg"
import CatSVG from "../assets/svg/cat-optimized.svg"
import pathOfTheWindMp3 from "../assets/mp3/pathOfTheWind(short).mp3"
import dearlyBelovedMp3 from "../assets/mp3/dearlyBeloved.mp3"
import findMyWayMp3 from "../assets/mp3/findMyWay.mp3"
import timeLapseVideo from "../assets/video/timelapseWithAudio.webm"

// Modal accessibility
Modal.setAppElement("#___gatsby")

function App() {
  // const { logout } = useAuth0();
  const [tabTitle, setTabTitle] = useState("Happy Birthday 🍰")
  const [modalIsOpen, setIsOpen] = useState(false)
  const [currentModal, setCurrentModal] = useState("")
  const [videoPlaying, setVideoPlaying] = useState(false)

  const pathOfTheWindRef = useRef(null)
  const pianoRef = useRef(null)
  const dearlyBelovedRef = useRef(null)
  const findMyWayRef = useRef(null)

  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { slug: { eq: "/" } }) {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    `
  )

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  // Same as componentDidMount()
  useEffect(() => {
    console.log(
      `%c Happy Birthday!!! 🎂`,
      `background: #222; color: #a3ebfb; font-family: cursive; font-size: 24px; 
    `
    )

    function playPathOfTheWind() {
      const audioTagForWind = pathOfTheWindRef.current
      if (audioTagForWind.paused) {
        pauseAllMedia()
        audioTagForWind.play()
        changePathColor(false, "wind")
      } else {
        audioTagForWind.pause()
        changePathColor(true, "wind")
      }
    }

    function playPiano() {
      const audioTagForPiano = pianoRef.current
      if (audioTagForPiano.paused) {
        pauseAllMedia()
        audioTagForPiano.play()
        changePathColor(false, "piano")
      } else {
        audioTagForPiano.pause()
        changePathColor(true, "piano")
      }
    }

    function openTrecipe() {
      window.open("https://trecipe-app.herokuapp.com/", "_blank")
    }

    function openCastle() {
      window.open(
        "https://capturethecastle.itch.io/capture-the-castle/",
        "_blank"
      )
    }

    function openRewind() {
      setCurrentModal("rewind")
      openModal()
    }

    function openPicture() {
      setCurrentModal("picture")
      openModal()
    }

    function openGift() {
      setCurrentModal("gift")
      openModal()
    }

    function openPaper() {
      setCurrentModal("paper")
      openModal()
    }

    let pathOfTheWindSvg = document.getElementById("wind")
    pathOfTheWindSvg.onclick = playPathOfTheWind

    let pianoSvg = document.getElementById("piano")
    pianoSvg.onclick = playPiano

    let computerSvg = document.getElementById("computer1")
    computerSvg.onclick = openTrecipe

    let castleSvg = document.getElementById("captureCastle")
    castleSvg.onclick = openCastle

    let rewindSvg = document.getElementById("rewind")
    rewindSvg.onclick = openRewind

    let pictureSvg = document.getElementById("picture")
    pictureSvg.onclick = openPicture

    let giftSvg = document.getElementById("gift")
    giftSvg.onclick = openGift

    let paperSvg = document.getElementById("paper")
    paperSvg.onclick = openPaper
  }, [])

  function changePathColor(reset, elementId) {
    let parentElement = document.getElementById(elementId)
    let childElements = Array.from(parentElement.children)

    childElements.forEach(childElement => {
      if (childElement.id !== "clickableRect") {
        childElement.style.stroke = reset ? "#000" : "url(#skyGradient)"
      }
    })
  }

  function pauseAllMedia() {
    pathOfTheWindRef.current.pause()
    pianoRef.current.pause()
    changePathColor(true, "piano")
    changePathColor(true, "wind")
    setVideoPlaying(false)
  }

  function pauseDearlyBeloved() {
    dearlyBelovedRef.current.pause()
  }

  function playTimeLapse() {
    pauseAllMedia()
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function afterModalOpen() {
    if (currentModal === "paper") {
      let findMyWayElement = document.getElementById("findMyWay");
      findMyWayElement.onclick = playFindMyWay;
    }
  }

  function playFindMyWay() {
    const audioTagForFindMyWay = findMyWayRef.current
    if (audioTagForFindMyWay.paused) {
      pauseDearlyBeloved()
      audioTagForFindMyWay.play()
    } else {
      audioTagForFindMyWay.pause()
    }
  }

  // Pass to children component
  function updateTabTitle(title) {
    setTabTitle(title)
  }

  function playVideo(bool) {
    if (bool) {
      pauseAllMedia()
      setVideoPlaying(true)
    } else {
      setVideoPlaying(false)
    }
  }

  let modalContent = <></>

  if (currentModal === "rewind") {
    modalContent = (
      <>
        <div
          style={{
            float: `right`,
          }}
        >
          <a href={`/background.bmp`} download className={styles.buttonInModal}>
            <VscArrowDown />
          </a>
          <button onClick={closeModal} className={styles.buttonInModal}>
            <VscChromeClose />
          </button>
        </div>
        <video
          className={styles.timeLapseInModal}
          controls
          onPlay={() => playTimeLapse()}
        >
          <source src={timeLapseVideo} type="video/webm" />
        </video>
      </>
    )
  } else if (currentModal === "picture") {
    modalContent = (
      <>
        <div
          style={{
            display: `flex`,
            justifyContent: `space-between`,
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            "Can you see without glasses???"
          </div>
          <div>
            <a
              href={`/who-need-glasses.jpg`}
              download
              className={styles.buttonInModal}
            >
              <VscArrowDown />
            </a>
            <button onClick={closeModal} className={styles.buttonInModal}>
              <VscChromeClose />
            </button>
          </div>
        </div>

        <img
          className={styles.imageInModal}
          src={`/who-need-glasses.jpg`}
          alt="who-need-glasses.jpg"
        />
      </>
    )
  } else if (currentModal === "gift") {
    modalContent = (
      <>
        <div
          style={{
            display: `flex`,
            justifyContent: `space-between`,
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Tim Horton Gift Card to fuel your coffee runs!{" "}
            <span role="img" aria-label="emoji">
              ☕
            </span>
          </div>

          <button onClick={closeModal} className={styles.buttonInModal}>
            <VscChromeClose />
          </button>
        </div>

        <CatSVG onClick={openGiftCard} />
      </>
    )

    function openGiftCard() {
      window.open("https://astral.cylim.net/icedCoffee.pdf", "_blank")
    }
  } else if (currentModal === "paper") {
    modalContent = (
      <>
        <div
          style={{
            display: `flex`,
          }}
        >
          <h1
            style={{
              width: `100%`,
              textAlign: `center`,
              paddingBottom: "20px"
            }}
          >
            {frontmatter.title}
          </h1>

          <button
            onClick={closeModal}
            className={styles.buttonInModal}
            style={{ position: "absolute", right: "2%" }}
          >
            <VscChromeClose />
          </button>
        </div>

        <audio
          onPlay={() => playTimeLapse()}
          ref={dearlyBelovedRef}
          autoPlay
          loop
          src={dearlyBelovedMp3}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>

        <audio
          onPlay={() => pauseDearlyBeloved()}
          ref={findMyWayRef}
          src={findMyWayMp3}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>

        <div
          style={{
            // textAlign: `center`,
            paddingRight: `clamp(10px, 20vw, 20vw)`,
            paddingLeft: `clamp(10px, 20vw, 20vw)`,
            fontFamily: `'Poly', georgia, serif`,
          }}
          className="bd-note"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </>
    )
  }

  return (
    <>
      <SEO title={tabTitle} />
      <main className={styles.mainContent}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          closeTimeoutMS={750}
          onAfterOpen={afterModalOpen}
          style={{
            content: {
              background: "#f9efe0",
              borderRadius: "10px",
            },
          }}
        >
          {modalContent}
        </Modal>

        <audio
          ref={pathOfTheWindRef}
          src={pathOfTheWindMp3}
          onEnded={() => changePathColor(true, "wind")}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>

        <AudioPlayer ref={pianoRef} changePathColor={changePathColor} />

        <div className={styles.gridContainer}>
          <div className={styles.svgWrap}>
            <MainSVG />
            <VideoPlayer
              playing={videoPlaying}
              playVideo={playVideo}
              sendTitle={updateTabTitle}
            />
          </div>
        </div>
        {/* <button
            id="logout"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            logout
          </button> */}
      </main>
      <main className={styles.hidden}>
        <img src={"/rotateScreen.png"}></img>
        <div className={styles.boldText}>Rotate your device</div>
        <div>Visit in Desktop for the best experience</div>
      </main>
    </>
  )
}

export default withAuthenticationRequired(App)
