import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import Loading from '../components/loading';
import App from "../components/app"
import { Router } from "@reach/router"

function IndexPage() {
  const { isLoading } = useAuth0()

  let mainComponent = isLoading? <Loading path="/"/> : <App path="/" />;

  return (
    <Router basepath="/">
      {mainComponent}
    </Router>
  )
}

export default IndexPage
