import React, { useState, useEffect, useRef } from "react"

// Video
import AliveVideo from "../assets/video/alive.webm"
import FlameVideo from "../assets/video/inFlames.webm"
import HomeVideo from "../assets/video/home.webm"
import BloomVideo from "../assets/video/bloom.webm"
import LightsVideo from "../assets/video/lights.webm"
import FindYourWayVideo from "../assets/video/findYourWay.webm"

// Lyrics
import HomeLyric from "../assets/lyrics/home.json"
import AliveLyric from "../assets/lyrics/alive.json"
import BloomLyric from "../assets/lyrics/bloom.json"
import LightsLyric from "../assets/lyrics/lights.json"
import FlameLyric from "../assets/lyrics/inFlames.json"
import FindYourWayLyric from "../assets/lyrics/findYourWay.json"

// Video player with lyric displaying on console
function VideoPlayer(props) {
  const [currentLyric, setCurrentLyric] = useState("")
  const [currentPartialLyric, setCurrentPartialLyric] = useState("")
  const [trackNumber, setTrackNumber] = useState(0)

  useEffect(() => {
    toggleVideo(props.playing);
  }, [props.playing])

  const videoRef = useRef(null)
  const songArray = [FindYourWayVideo, AliveVideo]
  const lyricsArray = [
    FindYourWayLyric,
    AliveLyric,
  ]
  const frameArray = [
    `/hb.png`, `/alive.png`
  ]
  // const songArray = [AliveVideo, HomeVideo, BloomVideo, FlameVideo, LightsVideo]
  // const lyricsArray = [
  //   AliveLyric,
  //   HomeLyric,
  //   BloomLyric,
  //   FlameLyric,
  //   LightsLyric,
  // ]

  function updateLyric(event) {
    let currentVideoTime = Math.floor(event.target.currentTime)
    let remainTime = event.target.duration - event.target.currentTime
    let remainMinute = Math.floor(remainTime / 60)
    let remainSecond = Math.floor(remainTime % 60)

    let fetchedLyric = lyricsArray[trackNumber][currentVideoTime]
    // Update lyric and print them to console
    if (!!fetchedLyric && currentLyric !== fetchedLyric) {
      console.log(
        `%c${fetchedLyric}`,
        `background: #222; color: #a3ebfb; font-family: cursive; font-size: 24px; font-style:italic; 
            `
      )
      setCurrentLyric(fetchedLyric)
      updatePartialLyric(fetchedLyric, 0)
    }

    updateTabTitle(event, remainMinute, remainSecond)
  }

  // Update the partial lyric every 50ms
  function updatePartialLyric(newLyric, currentChar) {
    if (currentChar < newLyric.length) {
      let partialLyric = newLyric.slice(0, currentChar + 1)
      if (partialLyric.length !== newLyric.length) {
        partialLyric = partialLyric.concat("_")
      }
      setCurrentPartialLyric(partialLyric)
      setTimeout(() => {
        updatePartialLyric(newLyric, currentChar + 1)
      }, 50)
    }
  }

  function updateTabTitle(event, remainMinute, remainSecond) {
    if (event.target.currentTime === 0) {
      props.sendTitle("Happy Birthday 🍰")
    } else {
      if (remainSecond < 10) {
        remainSecond = `0${remainSecond}`
      }
      props.sendTitle(`-${remainMinute}:${remainSecond} ${currentPartialLyric}`)
    }
  }

  function changeVideo(event) {
    setTrackNumber((trackNumber + 1) % songArray.length)
    resetLyric()
    event.target.load()
  }

  function resetLyric() {
    setCurrentLyric("")
    setCurrentPartialLyric("")
  }

  function toggleVideo(play) {
    const videoTag = videoRef.current
    if (play) {
      videoTag.play();
    } else {
      videoTag.pause();
    }  
  }

  return (
    <video
      ref={videoRef}
      controls
      poster={frameArray[trackNumber]}
      onPlay={() => props.playVideo(true)}
      onTimeUpdate={event => updateLyric(event)}
      onEnded={event => changeVideo(event)}
    >
      <source src={songArray[trackNumber]} type="video/webm" />
    </video>
  )
}

export default VideoPlayer
