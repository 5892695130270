import React, { useState } from "react"

import aliveMp3 from "../assets/mp3/alive2.mp3"
import rushMp3 from "../assets/mp3/rushOverMe.mp3"
import angelMp3 from "../assets/mp3/angelGuardian.mp3"
import planeMp3 from "../assets/mp3/paperPlane.mp3"
import pureMp3 from "../assets/mp3/pureWhite.mp3"
import ghibliMp3 from "../assets/mp3/ghibli.mp3"

const AudioPlayer = React.forwardRef(function AudioPlayer(props, ref) {
  const [pianoTrackNumber, setPianoTrackNumber] = useState(0)

  const pianoSongArray = [ghibliMp3, aliveMp3, rushMp3, angelMp3, planeMp3, pureMp3]

  function changePianoTrack(event) {
    setPianoTrackNumber((pianoTrackNumber + 1) % pianoSongArray.length)
    props.changePathColor(true, "piano");
    event.target.load()
  }

  return (
    <audio
      ref={ref}
      src={pianoSongArray[pianoTrackNumber]}
      onEnded={event => changePianoTrack(event)}
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  )
})

export default AudioPlayer
